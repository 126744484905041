import { Alert, HStack, IAlertProps, Text, VStack } from 'native-base';
import React from 'react';
import { toast } from 'react-toastify';

interface ToastProps {
    description?: string;
    title: string;
    status?: IAlertProps['status'];
    variant?: IAlertProps['variant'];
}

const Toast = ({ description, title, status, variant = 'subtle' }: ToastProps) => (
    <Alert alignSelf="center" flexDirection="row" maxWidth="100%" px="5" status={status} variant={variant}>
        <VStack flexShrink={1} space={1} w="100%">
            <HStack flexShrink={1} space={2}>
                <Alert.Icon mt="1" />
                <Text
                    color={status === undefined ? 'lightText' : 'darkText'}
                    flexShrink={1}
                    fontSize="md"
                    fontWeight="medium"
                >
                    {title}
                </Text>
            </HStack>
            {description ? <Text color={status === undefined ? 'lightText' : 'darkText'}>{description}</Text> : null}
        </VStack>
    </Alert>
);

/**
 * Displays a toast about the success of form submission.
 * @param title The title of the toast
 * @param description The description of the toast
 */
export const invokeSuccessToast = (title: string, description: string) =>
    toast(<Toast description={description} status="success" title={title} />);

/**
 * Displays a toast for an neutral info.
 * @param title The title of the toast
 * @param description The description of the toast
 */
export const invokeInfoToast = (title: string, description: string) =>
    toast(<Toast description={description} status="info" title={title} />);

export const invokeErrorToast = (title: string, description: string) =>
    toast(<Toast description={description} status="error" title={title} />);

export default Toast;
