import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import fr from './locales/fr.json';
import global from './locales/global.json';
import nl from './locales/nl.json';
import th from './locales/th.json';

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translation: { ...en, ...global },
            },
            fr: {
                translation: { ...fr, ...global },
            },
            nl: {
                translation: { ...nl, ...global },
            },
            th: {
                translation: { ...th, ...global },
            },
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        returnNull: false,
    });

export default i18n;
