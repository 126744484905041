import { IPaginationOptions, usePagination } from '@sparklin/react.api.hooks.use-pagination';
import { isDefined } from '@sparklin/react.api.utils.is-defined';
import { ArrowUpIcon, Badge, Box, Button, Fab, HStack, Icon, Text, useMediaQuery, VStack } from 'native-base';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useNavigate, useParams } from 'react-router-dom';
import { FixedSizeList, ListChildComponentProps, ListOnScrollProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import CommunityBadge from '../../components/Badge/CommunityBadge';
import UserBadge from '../../components/Badge/UserBadge';
import ButtonIcon from '../../components/Button/ButtonIcon';
import EditButton from '../../components/Button/EditButton';
import Duration from '../../components/Duration/Duration';
import ExpenseUserMonthSelection from '../../components/ExpenseUsersMonthSelection/ExpenseUsersMonthSelection';
import FormModal from '../../components/FormModal/FormModal';
import Table from '../../components/Table/Table';
import { ColumnsDescription } from '../../components/Table/types';
import { SCREENSIZE } from '../../global';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import BaseDetails from '../base/BaseDetails';
import { ExportType } from '../Sites/types';
import AddOrEditUserModalContent from '../Users/AddUserModalContent/AddOrEditUserModalContent';
import DeleteUserModalContent from '../Users/DeleteUserModalContent/DeleteUserModalContent';
import { Company } from '../Users/types';
import { UserCharge, UserDetails } from './types';
import { ReactComponent as AdminIcon } from '../../assets/icons/admin.svg';
import { ReactComponent as ChargeIcon } from '../../assets/icons/charge.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_black_24dp.svg';
import { ReactComponent as RequestQuoteIcon } from '../../assets/icons/request_quote.svg';
import avatar from '../../assets/images/user-2x.png';

const formatDuration = (value: number) => <Duration duration={value} />;

const LIMIT = 20;

const UserDetailsPage = () => {
    const { id } = useParams<{ id: string }>() as { id: string };
    const { t } = useTranslation();
    const company = useAppSelector(companySelect) as Company;
    const listRef = useRef<FixedSizeList | null>(null);
    const { next, init, inited, meta, loading: chargeLoading, setOptions } = usePagination<UserCharge>();
    const [showFullList, setShowFullList] = useState(false);
    const [user, setUser] = useState<UserDetails | undefined>(undefined);
    const [charges, setCharges] = useState<UserCharge[]>([]);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [expenseMonthSelectionModalOpen, setExpenseMonthSelectionModalOpen] = useState(false);
    const [exportType, setExportType] = useState<ExportType>(ExportType.EXPENSE);
    const navigate = useNavigate();
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });

    const titleText = useMemo(
        () => t(exportType === ExportType.EXPENSE ? 'userDetails.expenses.title' : 'userDetails.charges.title'),
        [exportType, t],
    );

    const formatChargeType = (value: string): string =>
        value === 'PROFESSIONAL' ? t('common.charge.professional') : t('common.charge.personal');

    const formatDate = (value: Date | string) =>
        t('common.template.date', {
            val: new Date(value),
        });

    const formatConsumedEnergy = (value: number) => t('common.units.kWh', { val: value });

    const columnsDescription: ColumnsDescription<UserCharge> = {
        siteName: {
            id: 'siteName',
            name: t('userDetails.columns.site'),
        },
        socket: {
            id: 'socket',
            name: t('userDetails.columns.socket'),
            formatData: (value) => value.name,
        },
        duration: {
            id: 'duration',
            name: t('userDetails.columns.duration'),
            formatData: formatDuration,
        },
        date: {
            id: 'date',
            name: t('userDetails.columns.date'),
            formatData: formatDate,
        },
        consumedEnergy: {
            id: 'consumedEnergy',
            name: t('userDetails.columns.consumedEnergy'),
            formatData: formatConsumedEnergy,
        },
        type: {
            id: 'type',
            name: t('common.charge.type'),
            formatData: formatChargeType,
        },
    };

    const fetchCharges = useCallback(async () => {
        if (inited && !chargeLoading) {
            try {
                if (Number(meta?.currentPage || 0) < Number(meta?.totalPages || 1)) {
                    const { items } = await next();
                    setCharges([...charges, ...items]);
                }
            } catch (err) {
                setError(err as Error);
            }
        }
    }, [inited, charges, setCharges, next]);

    useEffect(() => {
        if (isDefined(company)) {
            setLoading(true);
            setOptions({ limit: LIMIT });
            providers.usersProvider
                .getUserDetails(company.id, id)
                .then((userDetails) => {
                    setUser(userDetails);
                    init((options: IPaginationOptions) =>
                        providers.chargesProvider.getChargesForUser(company.id, id, options),
                    );
                })
                .catch(setError)
                .finally(() => setLoading(false));
        }
    }, [company]);

    useEffect(() => {
        if (!meta && !chargeLoading) {
            fetchCharges();
        }
    }, [meta, chargeLoading, fetchCharges]);

    const refreshUser = () => {
        if (isDefined(company)) {
            setLoading(true);
            providers.usersProvider
                .getUserDetails(company.id, id)
                .then((userDetails) => setUser(userDetails))
                .catch(setError)
                .finally(() => setLoading(false));
        }
    };

    const headerAction = user && (
        <HStack space={2}>
            <EditButton
                label={t('userDetails.edit.button')}
                onPress={() => setEditModalOpen(true)}
                variant={isSmallScreen ? 'unstyled' : 'outline'}
            />
            <ButtonIcon
                icon={
                    <Icon size={6}>
                        <DeleteIcon />
                    </Icon>
                }
                onPress={() => setDeleteModalOpen(true)}
                testID="delete-user-button"
                variant="ghost"
            />
        </HStack>
    );

    const headerChildren = user && (
        <VStack testID="user-header">
            <HStack alignItems="center" mt={2} space={3}>
                <Text>{formatPhoneNumber(user.phoneNumber)}</Text>
            </HStack>
            <HStack flexWrap="wrap" space={3}>
                {user.communities.length > 0
                    ? user.communities.map((community) => (
                          <CommunityBadge key={community.id} community={community} my={2} />
                      ))
                    : null}
            </HStack>
        </VStack>
    );

    const title =
        user &&
        (!isSmallScreen ? (
            <HStack alignItems="center" space={3}>
                {user.isManager && <Icon as={AdminIcon} />}
                <Text>
                    {user.firstName} {user.lastName}
                </Text>
                <UserBadge status={user.status} />
            </HStack>
        ) : (
            <VStack w="full">
                <HStack justifyContent="flex-end">
                    {user.isManager && <Icon as={AdminIcon} mr={3} size={4} />}
                    <UserBadge status={user.status} />
                </HStack>
                <Text fontSize={16} mt={2}>
                    {user.firstName} {user.lastName}
                </Text>
            </VStack>
        ));

    const downloadButtons = (
        <Button.Group size="sm" isAttached>
            <Button
                leftIcon={
                    <Icon size={4}>
                        <RequestQuoteIcon />
                    </Icon>
                }
                mr={0.5}
                onPress={() => {
                    setExportType(ExportType.EXPENSE);
                    setExpenseMonthSelectionModalOpen(true);
                }}
                testID="request-note-icon"
                w={[10, 'max-content']}
            >
                {isSmallScreen ? '' : t('userDetails.expenses.button')}
            </Button>
            <Button
                leftIcon={
                    <Icon size={4}>
                        <ChargeIcon />
                    </Icon>
                }
                onPress={() => {
                    setExportType(ExportType.CHARGE);
                    setExpenseMonthSelectionModalOpen(true);
                }}
                testID="request-charges-icon"
                w={[10, 'max-content']}
            >
                {isSmallScreen ? '' : t('userDetails.charges.button')}
            </Button>
        </Button.Group>
    );

    const isItemLoaded = (index: number) => meta?.currentPage === meta?.totalPages || index < charges.length;

    return (
        <BaseDetails
            backUrl="/users/"
            error={error}
            headerAction={headerAction}
            headerChildren={headerChildren}
            hideHeader={showFullList}
            imageSrc={avatar}
            isLoading={loading}
            title={title}
        >
            <FormModal
                isOpen={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                testID="edit-user-modal"
                title={t('userDetails.edit.title')}
            >
                <AddOrEditUserModalContent
                    editingUser={user}
                    onSubmitted={() => {
                        refreshUser();
                        setEditModalOpen(false);
                    }}
                />
            </FormModal>
            <FormModal
                isOpen={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                testID="delete-user-modal"
                title={t('userDetails.delete.title')}
            >
                <DeleteUserModalContent
                    onSubmitted={() => {
                        navigate(`/users`);
                        setDeleteModalOpen(false);
                    }}
                    user={user}
                />
            </FormModal>
            {isDefined(user) && isDefined(company) ? (
                <FormModal
                    isOpen={expenseMonthSelectionModalOpen}
                    onClose={() => setExpenseMonthSelectionModalOpen(false)}
                    testID="expenses-month-modal"
                    title={titleText}
                >
                    <ExpenseUserMonthSelection
                        closeModal={() => setExpenseMonthSelectionModalOpen(false)}
                        company={company}
                        exportType={exportType}
                        user={user}
                    />
                </FormModal>
            ) : null}
            {!isSmallScreen ? (
                <Table
                    asideTitle={downloadButtons}
                    columns={columnsDescription}
                    data={charges}
                    itemsPerPage={LIMIT}
                    itemsTotal={meta?.totalItems || 0}
                    loadMoreItems={fetchCharges}
                    loading={chargeLoading}
                    noDataLabel={t('userDetails.table.noDataLabel')}
                    title={t('userDetails.table.title', { nb: meta?.totalItems })}
                />
            ) : (
                <Box>
                    {!showFullList && (
                        <Box flexDirection="row" justifyContent="space-between" mb={10}>
                            <Text>{t('userDetails.table.title', { nb: meta?.totalItems })}</Text>
                            {downloadButtons}
                        </Box>
                    )}

                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={meta?.totalItems || 0}
                        loadMoreItems={fetchCharges}
                        minimumBatchSize={50}
                    >
                        {({ onItemsRendered, ref }) => (
                            <FixedSizeList
                                ref={(elem) => {
                                    ref(elem);
                                    listRef.current = elem;
                                }}
                                height={window.innerHeight}
                                itemCount={charges.length || 0}
                                itemSize={91}
                                onItemsRendered={onItemsRendered}
                                onScroll={({ scrollOffset, scrollDirection }: ListOnScrollProps) => {
                                    if (!showFullList && scrollOffset > 0 && scrollDirection === 'forward') {
                                        setShowFullList(true);
                                    }
                                    if (scrollOffset === 0 && scrollDirection === 'backward') {
                                        setShowFullList(false);
                                    }
                                }}
                                width="100%"
                            >
                                {({ index, style }: ListChildComponentProps) => {
                                    if (!isItemLoaded(index)) {
                                        return null;
                                    }
                                    const item = charges.at(index) as UserCharge;

                                    return (
                                        <div style={style}>
                                            <Box
                                                _dark={{
                                                    borderColor: 'muted.500',
                                                }}
                                                borderBottomWidth="0.5"
                                                borderColor="muted.800"
                                                pl={['0', '4']}
                                                pr={['0', '5']}
                                                py="2"
                                            >
                                                <HStack justifyContent="space-between" space={[2, 3]}>
                                                    <VStack maxW="250px">
                                                        <Text color="gray.500" fontWeight={300}>
                                                            {formatDate(item.date)}
                                                        </Text>
                                                        <Text fontWeight={500}>{item.socket.name}</Text>
                                                        <Badge
                                                            bg="primary.700"
                                                            color="white"
                                                            flexDirection="row"
                                                            mt={1}
                                                            variant="solid"
                                                        >
                                                            <HStack alignItems="center" justifyContent="center">
                                                                <Text fontSize={12}>{item.siteName} </Text>
                                                            </HStack>
                                                        </Badge>
                                                    </VStack>
                                                    <VStack alignItems="flex-end" justifyContent="center" minW={100}>
                                                        <Text>{formatDuration(item.duration)}</Text>
                                                        <Text fontWeight={600}>
                                                            {formatConsumedEnergy(item.consumedEnergy)}
                                                        </Text>
                                                        <Text>{formatChargeType(item.type)}</Text>
                                                    </VStack>
                                                </HStack>
                                            </Box>
                                        </div>
                                    );
                                }}
                            </FixedSizeList>
                        )}
                    </InfiniteLoader>
                    <Fab
                        display={showFullList ? 'inline' : 'none'}
                        icon={
                            <Icon color="gray.50" size={4}>
                                <ArrowUpIcon />
                            </Icon>
                        }
                        onPress={() => {
                            listRef.current?.scrollTo(0);
                            setShowFullList(false);
                        }}
                        position="fixed"
                        shadow={2}
                        size="sm"
                    />
                </Box>
            )}
        </BaseDetails>
    );
};

export default UserDetailsPage;
