// Todo : Refactor to shared types on monorepo architecture
export enum CommunityUsageMode {
    ALL = 0,
    PERSONAL = 1,
    PROFESSIONAL = 2,
}

export interface Community {
    id: CommunityId;
    name: string;
    usageMode: CommunityUsageMode;
    paymentEnabled: boolean;
    isDefault: boolean;
    isPublic: boolean;
}

export enum LanguageCode {
    FR = 'fr',
    EN = 'en',
    NL = 'nl',
    TH = 'th',
}
